import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { addListUser as addListUserAction } from '../../../../redux/actions/user.action';
import { http } from '../../../../utils/http';
import { columns } from './columns';
import EditModalUser from '../../components/EditModalUser/EditModalUser';
import './UserPage.scss';
class UserPage extends Component {
   state = {
      userSelected: null,
      showModal: false,
      textFilter: '',
   };

   async componentDidMount() {
      await this._getUsers();
   }

   async _getUsers() {
      http('/user', 'GET', {})
         .then((res) => {
            this.props.addListUser(res.data.data);
         })
         .catch((err) => console.log(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, userSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, userSelected: user });
   }

   render() {
      const { users } = this.props;
      const { textFilter } = this.state;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Usuarios Administrativos</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getUsers.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div className="mb-3">
               <div className="relative mb-4 flex w-[400px] flex-wrap items-stretch">
                  <input
                     type="search"
                     className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                     placeholder="Buscar usuario"
                     aria-label="Search"
                     aria-describedby="button-addon1"
                     value={textFilter}
                     onChange={(event) => {
                        this.setState({
                           textFilter: event.target.value,
                        });
                     }}
                  />
               </div>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={
                     users
                        ? users.filter((item) => {
                             return item.fullName
                                .toLowerCase()
                                .includes(textFilter.toLowerCase());
                          })
                        : []
                  }
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalUser
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               user={this.state.userSelected}
               refresUsers={() => {
                  this._getUsers();
               }}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   users: state.userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
   addListUser: (users) => dispatch(addListUserAction(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);

import { IconButton, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AgGrid from '../../../../components/AgGrid/AgGrid';
import { addListUserChild as addListUserActionChild } from '../../../../redux/actions/user.action';
import { http } from '../../../../utils/http';
import { columns } from './columns';
import './UserChildPage.scss';
import EditModalUserChild from '../../components/EditModalUserChild/EditModalUserChild';
class UserChildPage extends Component {
   state = {
      userSelected: null,
      showModal: false,
   };

   async componentDidMount() {
      await this._getUsers();
   }

   async _getUsers() {
      const {
         match: {
            params: { id },
         },
      } = this.props;
      http(`/user/${id}/child`, 'GET', {})
         .then((res) => {
            if (!res) return;
            const { data } = res;

            this.props.addListUseChild(data.data);
         })
         .catch((err) => console.log(err));
   }

   _showModal() {
      this.setState({ showModal: true });
   }

   _hideModal() {
      this.setState({ showModal: false, userSelected: null });
   }

   _handleRowClicked(value) {
      const user = value.data;
      this.setState({ showModal: true, userSelected: user });
   }

   render() {
      const {
         location: { state },
      } = this.props;
      return (
         <div className="Page">
            <div className="Page-header">
               <div className="header-title">
                  <h1>Usuarios hijos de {state.user.fullName}</h1>
               </div>
               <div className="header-actions">
                  <Tooltip title="Refrescar" aria-label="Refrescar">
                     <IconButton onClick={this._getUsers.bind(this)}>
                        <i className="material-icons">refresh</i>
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Crear" aria-label="Crear">
                     <IconButton onClick={this._showModal.bind(this)}>
                        <i className="material-icons">add</i>
                     </IconButton>
                  </Tooltip>
               </div>
            </div>
            <div>
               <Tooltip title="Regresar" aria-label="Crear">
                  <IconButton
                     onClick={() => {
                        this.props.history.goBack();
                     }}>
                     <i className="material-icons">arrow_back</i>
                  </IconButton>
               </Tooltip>
            </div>
            <div className="Page-content">
               <AgGrid
                  rowData={this.props.usersChildren.filter(
                     (user) => user.user._id === this.props.match.params.id,
                  )}
                  columnDefs={columns}
                  onRowDoubleClicked={this._handleRowClicked.bind(this)}
               />
            </div>
            <EditModalUserChild
               isOpen={this.state.showModal}
               onClose={this._hideModal.bind(this)}
               user={this.state.userSelected}
               userParentId={this.props.match.params.id}
            />
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   users: state.userReducer.users,
   usersChildren: state.userReducer.usersChildren,
});

const mapDispatchToProps = (dispatch) => ({
   addListUseChild: (users) => dispatch(addListUserActionChild(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserChildPage);

import { format, parseISO } from 'date-fns';
import { updateUser } from '../../../../redux/actions/user.action';

export const columns = [
   {
      headerName: 'Nombre',
      field: 'name',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return `${data.fullName}`;
      },
   },

   {
      headerName: 'Correo',
      field: 'email',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Empresa',
      field: 'nameCompany',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Fecha Alta',
      field: 'createdAt',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return format(parseISO(data.createdAt), 'yy-MM-dd hh:mm');
      },
   },
   {
      headerName: 'Historial',
      field: 'credits._id',
      filter: true,
      sortable: true,
      width: 150,
      cellRendererParams: {
         title: 'Historial',
      },
      cellRenderer: 'agButtonHistoryUserCellRenderer',
   },
   {
      headerName: 'Historial',
      field: 'credits._id',
      filter: true,
      sortable: true,
      width: 150,
      cellRendererParams: {
         title: 'Hijos',
      },
      cellRenderer: 'agButtonViewUserChildCellRenderer',
   },
   {
      headerName: 'Actuales',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return data.credits.balance + (data.credits.paymentCredits || 0);
      },
      width: 130,
   },

   {
      headerName: 'Comprados',
      field: 'credits.paymentCredits',
      filter: true,
      sortable: true,
      resizable: true,
      width: 130,
   },
   {
      headerName: 'Usados',
      field: 'credits.usedMonth',
      filter: true,
      sortable: true,
      resizable: true,
      width: 130,
   },
   {
      headerName: 'Recarga',
      field: 'credits.recharge',
      filter: true,
      sortable: true,
      resizable: true,
      width: 130,
   },
   {
      headerName: 'Acciones',
      cellRenderer: 'agActionButtonCellRenderer',
      width: 150,
      cellRendererParams: {
         active: {
            url: '/user',
            action: updateUser,
         },
         sendEmail: true,
      },
   },
];

/*
{
      headerName: 'Créditos actuales',
      filter: true,
      sortable: true,
      resizable: true,
      valueGetter: ({ data }) => {
         return data.credits.balance + (data.credits.paymentCredits || 0);
      },
   },

  {
      headerName: 'Créditos comprados disponibles',
      field: 'credits.paymentCredits',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos usados',
      field: 'credits.usedMonth',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos reservados',
      field: 'credits.usedMonth',
      filter: true,
      sortable: true,
      resizable: true,
   },
   {
      headerName: 'Créditos de recarga',
      field: 'credits.recharge',
      filter: true,
      sortable: true,
      resizable: true,
   },
*/
